<template>
  <div id="preview">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>
          {{ pageTitle }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-form ref="form1" v-model="valid1" lazy-validation>
        <v-card-text>
          <h5><span class="text-danger">*</span> indicates required field</h5>
        </v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="4">
              <h5><span class="text-danger">*</span> Description</h5>
              <v-text-field
                :rules="DescriptionRules"
                v-model="Description"
                hint="description"
                type="text"
                required
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4">
              <h5><span class="text-danger">*</span> Plan Amount</h5>
              <v-text-field
                v-model="Amount"
                :rules="AmountRules"
                hint="Amount"
                type="number"
                required
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4">
              <h5><span class="text-danger">*</span> TaxPercentage</h5>
              <v-text-field
                v-model="TaxPercentage"
                :rules="AmountRules"
                hint="TaxPercentage"
                type="number"
                min="0"
                required
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4">
              <h5><span class="text-danger">*</span> Start Date</h5>
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
                required
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="StartDate"
                    readonly
                    v-on="on"
                    dense
                    outlined
                    required
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="StartDate"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4">
              <h5><span class="text-danger">*</span> End Date</h5>
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
                required
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="EndDate"
                    readonly
                    v-on="on"
                    dense
                    outlined
                    required
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="EndDate"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4">
              <h5><span class="text-danger">*</span> Expiry Date</h5>
              <v-menu
                v-model="menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
                required
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="ExpiryDate"
                    readonly
                    v-on="on"
                    dense
                    outlined
                    required
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="ExpiryDate"
                  @input="menu3 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4">
              <h5>Fine</h5>
              <v-switch
                v-model="FineFlag"
                :label="`${FineFlag ? 'Yes' : 'No'}`"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" v-if="FineFlag">
              <h5><span class="text-danger">*</span> Fine Amount</h5>
              <v-text-field
                v-model="FineAmount"
                :rules="FineAmountRules"
                hint="Fine Amount"
                type="text"
                min="0"
                required
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" v-if="FineFlag">
              <h5><span class="text-danger">*</span> Fine End Date</h5>
              <v-menu
                v-model="menu4"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
                required
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="FineEndDate"
                    readonly
                    v-on="on"
                    dense
                    outlined
                    required
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="FineEndDate"
                  @input="menu4 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <label>* Active Status</label>
              <v-switch
                v-model="ActiveStatus"
                :label="`${ActiveStatus ? 'Active' : 'Inactive'}`"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click.prevent="closePrompt"
            elevation="30"
            shaped
            tile
            small
            color="#a52a2a"
            class="font-size-h6 px-10 mr-3 white--text"
          >
            Close
          </v-btn>
          <v-btn
            :disabled="!valid1"
            @click.prevent="confirmAlert"
            :loading="SubmitFlag"
            elevation="30"
            shaped
            tile
            small
            color="#1db954"
            class="font-size-h6 px-10 mr-3 white--text"
          >
            Save
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>
  
  <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    editRecordPrompt: {
      type: Boolean,
      required: true,
    },
    pageTitle: {
      type: String,
    },
    pageDescription: {
      type: String,
    },
    recordData: {
      type: Array,
      required: true,
    },
    Year: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,

      ResultFlag: false,

      rows: [],

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: "",
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      ContributionTypeIdRules: [(v) => !!v || "Contribution Type is required"],
      ContributionTypeId: "",
      ContributionTypeIdOptions: [],
      ContributionTypeIdOptionsLoading: false,

      PlanTypeOptions: [
        { value: "", text: "--Select Year--" },
        {
          value: 1,
          text: "Annual",
        },
        {
          value: 2,
          text: "Half year",
        },
        {
          value: 3,
          text: "Upgrade",
        },
      ],
      Year: "",
      StartDate: "",
      menu1: false,
      EndDate: "",
      menu2: false,
      Amount: "",
      TaxPercentage: "",
      FineEndDate: "",
      menu3: false,
      Description: "",
      ExpiryDate: "",
      menu4: false,
      PlanType: "",
      DescriptionRules: [(v) => !!v || "Description is required"],
      VotingFlagRules: [(v) => !!v || "Voting Permission is required"],
      AmountRules: [(v) => !!v || "Amount is required"],
      TaxPercentageRules: [
        // (v) => !!v || "Tax Percentage is required"
      ],

      FinalAmount: 0,

      FineFlag: 0,
      FineFlagRules: [(v) => !!v || "Fine is required"],

      DeadlineFlag: 0,
      DeadlineFlagRules: [(v) => !!v || "Is allow deadline is required"],

      ActiveStatus: 0,
      ActiveStatusRules: [(v) => !!v || "Active Status is required"],

      FineAmount: "",
      FineAmountRules: [
        // (v) => !!v || "Fine Amount is required"
      ],
      menu3: false,

      NoOfTimes: "",
      NoOfTimesRules: [
        // (v) => (v && v <= 0) || "No of times should be above or equal to 0",
      ],
    };
  },
  computed: {},
  watch: {},
  created() {
    // this.initialize()
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      // var recordIndex = this.recordIndex;
      console.log("recordData=" + JSON.stringify(this.recordData));
      console.log("year =", this.Year);
      console.log("year =", JSON.stringify(this.recordData.Year));
      this.JciYearCode = this.recordData.Year;
      this.StartDate = this.recordData.StartDate;
      this.EndDate = this.recordData.EndDate;
      this.Amount = this.recordData.Amount;
      this.TaxPercentage = this.recordData.Tax;
      this.FineEndDate = this.recordData.FineEndDate;
      this.PlanType = this.recordData.PlanType;
      this.ExpiryDate = this.recordData.PlanExpiryDate;
      this.Description = this.recordData.Description;
      this.FineFlag = this.recordData.FineFlag;
      this.FineAmount = this.recordData.FineAmount;
      this.ActiveStatus = this.recordData.ActiveStatus;
      this.getJciYearCodeOptions();
    },
    resetForm() {
      console.log("resetForm called");
      this.$refs.form1.reset();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideEditRecordPrompt", this.ResultFlag);
      this.refreshPageData();
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        this.toast("error", "Kindly fill the required fields");
      }
    },
    getJciYearCodeOptions() {
      this.LoadingFlag = true;
      this.JciYearCodeOptionsLoading = true;
      console.log("getJciYearCodeOptions called");
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      //   validate1 = false;

      if (validate1) {
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jcom/plan/update";
        var upload = {
          Plan: this.recordData.PlanId,
          Year: this.recordData.Year,
          StartDate: this.StartDate,
          EndDate: this.EndDate,
          Amount: this.Amount,
          TaxPercentage: this.TaxPercentage,
          Description: this.Description,
          ExpiryDate: this.ExpiryDate,
          FineEndDate: this.FineEndDate,
          PlanType: this.PlanType,
          FineFlag: this.FineFlag,
          FineAmount: this.FineAmount,
          ActiveStatus: this.ActiveStatus,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.ResultFlag = true;
              thisIns.resetForm();
              thisIns.closePrompt();
              thisIns.sweetAlert("success", output, true);
              this.toast("success", output);
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        thisIns.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>